import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import QRCode from "react-qr-code";
import mpPlate from "../img/mp.jpg";
import nagarparishadPlate3 from "../img/nagarparishadPlate3.jpeg";
import nagarparishadPlate4 from "../img/nagarparishadPlate4.jpeg";
import html2canvas from "html2canvas";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import "./qrcode.css";

const AdminQRGenerator = () => {
  const [numberOfQRs, setNumberOfQRs] = useState(0);
  const [qrCodes, setQrCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [backgroundType, setBackgroundType] = useState("mp");
  const [existingQrIds, setExistingQrIds] = useState(new Set());
  const [show, setShow] = useState(false);

  useEffect(() => {
    const fetchExistingQrIds = async () => {
      try {
        const response = await axios.get(
          "https://www.rcinfovision.in/api/get-all-qrs"
          // `http://localhost:5000/api/get-all-qrs`
        );
        if (response.status === 200) {
          const fetchedQrIds = response.data.map((qr) => qr.qrId);
          setExistingQrIds(new Set(fetchedQrIds));
          console.log("Total existing QR IDs fetched: ", fetchedQrIds.length);
        } else {
          console.error("Error fetching existing QR IDs:", response);
        }
      } catch (error) {
        console.error("Error fetching existing QR IDs", error);
      }
    };
    fetchExistingQrIds();
  }, []);

  const generateUniqueQRId = () => {
    let qrId;
    do {
      qrId =
        "25" +
        Math.floor(Math.random() * 10000)
          .toString()
          .padStart(4, "0"); // Generate a 4-digit number and pad it if necessary
    } while (existingQrIds.has(qrId)); // Only check against existing QR codes fetched from the database
    return qrId;
  };

  const handleGenerateQRs = () => {
    setShow(false);
    const newQrCodes = [];
    const existingIds = new Set(qrCodes.map((qr) => qr.qrId));

    for (let i = 0; i < numberOfQRs; i++) {
      const qrId = generateUniqueQRId(existingIds);
      existingIds.add(qrId);
      const qrUrl = `https://www.rcinfovision.in/get-details/qr/${qrId}`;
      // const qrUrl = `http://localhost:5000/get-details/qr/${qrId}`;
      newQrCodes.push({ qrId, qrCodeUrl: qrUrl, backgroundType });
    }

    setQrCodes((prevQrCodes) => [...prevQrCodes, ...newQrCodes]);
  };

  const handleSaveQRs = async (event) => {
    event.preventDefault(); // Prevent form submission or page refresh
    setLoading(true);
    setError(null);
    try {
      const qrDataToSend = qrCodes.map((qr) => ({
        qrId: qr.qrId,
        qrCodeUrl: qr.qrCodeUrl,
      }));

      const response = await axios.post(
        "https://www.rcinfovision.in/api/save-qr",
        // "http://localhost:5000/api/save-qr",
        {
          qrCodes: qrDataToSend,
        }
      );
      if (response.data.status === 201) {
        setShow(true);
        alert(response.data.message);
        // Do not clear qrCodes here to allow downloading after saving
        console.log(response.data.data);
        setQrCodes(response.data.data);
      } else if (response.data.status === 204) {
        setShow(true);
        alert(response.data.message);
        setQrCodes(response.data.data);
      } else if (response.data.status === 200) {
        setShow(true);
        setQrCodes(response.data.data);
      } else {
        console.error("Error response from server:", response);
        setError("Error saving QR codes");
      }
    } catch (error) {
      console.error("Error saving QR codes", error);
      setError("Error saving QR codes");
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadAll = useCallback(async () => {
    setLoading(true);
    const zip = new JSZip();
    const batchSize = 50;

    try {
      for (let i = 0; i < qrCodes.length; i += batchSize) {
        const batch = qrCodes.slice(i, i + batchSize);
        await Promise.all(
          batch.map(async (qr) => {
            const node = document.getElementById(`qr-${qr.qrId}`);
            const canvas = await html2canvas(node, {
              width: 1800,
              height: 900,
              scale: 1,
              useCORS: true,
            });
            const dataUrl = canvas.toDataURL("image/png");
            const imgData = dataUrl.split(",")[1];
            zip.file(`${qr.qrId}.png`, imgData, { base64: true });
          })
        );
      }

      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, "qr-codes.zip");

      // Clear QR codes from state after download
      setQrCodes([]);
    } catch (error) {
      console.error("Error downloading QR codes", error);
      setError("Error downloading QR codes");
    } finally {
      setLoading(false);
    }
  }, [qrCodes]);

  return (
    <div>
      <h2 className="my-3">Generate QR Codes</h2>
      <input
        type="number"
        value={numberOfQRs}
        onChange={(e) => setNumberOfQRs(Number(e.target.value))}
        placeholder="Enter number of QR codes"
      />
      <select
        value={backgroundType}
        onChange={(e) => setBackgroundType(e.target.value)}
      >
        <option value="mp">Government of MP Plate</option>
        <option value="nagarparishadkatangi">Nagarparishad Katangi</option>
        <option value="nagarparishadsamsabad">Nagarparishad Samsabad</option>
      </select>
      <button onClick={handleGenerateQRs} className="qr-btn">
        Generate QR Codes
      </button>
      {error && <p className="error">{error}</p>}
      {qrCodes.length > 0 && (
        <div>
          <button
            onClick={handleSaveQRs}
            className="qr-btn my-3"
            disabled={loading}
          >
            {loading ? "Saving..." : "Save QR Codes"}
          </button>
          {show ? (
            <>
              <button
                onClick={handleDownloadAll}
                className="qr-btn my-3"
                style={{ float: "right" }}
                disabled={loading || qrCodes.length === 0} // Disable if no QR codes
              >
                {loading ? "Downloading..." : "Download All"}
              </button>
              {error && <p className="error">{error}</p>}
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>QR ID</th>
                    <th>QR Image</th>
                    <th>QR URL</th>
                  </tr>
                </thead>
                <tbody>
                  {qrCodes.map((qr, index) => (
                    <tr key={qr.qrId}>
                      <td>
                        {index + 1} {qr.backgroundType}
                      </td>
                      <td>{qr.qrId}</td>
                      <td>
                        <div id={`qr-${qr.qrId}`} className="qr-plate">
                          <img
                            src={
                              backgroundType === "mp"
                                ? mpPlate
                                : backgroundType === "nagarparishadkatangi"
                                ? nagarparishadPlate3
                                : nagarparishadPlate4
                            }
                            alt="QR Plate"
                            className="plate-image"
                          />
                          {/* <img
                            src={mpPlate}
                            alt="QR Plate"
                            className="plate-image"
                          /> */}
                          <div className="qr-code-container">
                            <QRCode value={qr.qrCodeUrl} />
                          </div>
                          <div className="mp-text">
                            <span className="qr-id">{qr.qrId}</span>
                          </div>
                        </div>
                      </td>
                      <td>{qr.qrCodeUrl}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <p className="text-center mt-5">Qr code not available</p>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminQRGenerator;
